@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.own_flex_classes{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    background: #0D203C;
    padding: 17px 0;
}
.img_left_side_box{
    display: flex;
    align-items: center;
}
.img_left_side_box > img {
    height: 100px;
    padding: 0;
    margin: 0;
    position: relative;
    top: -10px;
    left: -15px;
}
.img_left_side_box .stronge_head{
    font-size: 30px;
    color: white;
    letter-spacing: 1px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}
.img_left_side_box h3{
    display: flex;
    flex-direction: column;
}
.stronge_head span.border_span{
    position: relative;
    display: inline-block;
    margin: 0px 10px;
}
/* .stronge_head span.border_span::before{
    content: "";
    position: absolute;
    left: 0%;
    height: 30px;
    width: 133%;
    background: white;
    z-index: 99999;
    border: 1px solid white;
    top: -22px;
} */
.img_left_side_box span.two{
    font-size: 22px;
    color: white;
    margin: 10px 0;
    letter-spacing: 1px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
}
.img_left_side_box span.three{
    font-size: 20px;
    color: green;
    letter-spacing: 1px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
}
.market_cup{
    display: flex;
}
.market_cup h3{
    display: flex;
    flex-direction: column;
    font-size: 20px;
    letter-spacing: 2px;
    color: white;
}
.market_cup h3 span.one{
    font-size: 30px;
    line-height: 30px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
}
.market_cup h3 span.two{
    font-size: 22px;
    line-height: 50px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
}
.margin-top-two{
    margin-top: 30px;
}
.buy_now{
    font-family: 'Lato', sans-serif;
    background: linear-gradient(90deg,#c09434,#caa344,#d3b154,#ddc064,#e7cf75,#e7cf75,#e7cf75,#e7cf75,#ddc064,#d3b154,#caa344,#c09434);
    color: #443107;
    border: none;
    padding: 7px 1px;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 20px;
    transition: all 0.5s;
    overflow: hidden;
    position: relative;
}
.buy_now:hover{
    color: #fff;
    animation: swoosh 0.4s ease-out 0.2s;
}

.buy_now:before,
.buy_now:after{
    content: "";
    background-color: #8f939396;
    height: 100%;
    width: 50%;
    opacity: 1;
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 0;
    transition: all 0.4s ease-out 0.1s;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.buy_now:after{
    opacity: 0;
    transform: scale(0.9,0.7);
    left: 0;
    transition: all 0.3s ease-out 0s;
}
.buy_now:before{ left: 100%; }
.buy_now:hover:after{
    opacity: 0.3;
    transform: scale(1);
}
.left-right-head{
    color: #F4E286;
    font-size: 25px;
    letter-spacing: 2px;
    padding-left: 10px;
    padding-top: 10px;







              
    font-family: 'Lato', sans-serif;
    font-weight: 600;
}
.comming_text{
    display: block;
    color: #F4E286;
    font-size: 25px;
    letter-spacing: 2px;
    padding-left: 10px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
}
.swap_box{
    background: #0D203C;
}
.exchange_links a {
    display: inline-flex;
    width: 45%;
    border: 0.2px solid #F4E286;
    padding: 1px 4px;
    margin: 5px 5px;
    object-fit: fill;
    border-radius: 5px;
    margin: 3px 5px;
}
.exchange_links a > img{
    /* height: 58px; */
    width: 100%;
    object-fit: fill;
    margin: 4px;
}
.portfolio{
    display: none;
}
div.col-lg-6 > canvas{
    background: #0D203C;
    margin: 17px 0;
    padding: 10px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
}

@media screen and (max-width:993px) {
    .img_left_side_box .stronge_head {
        font-size: 25px;
    }
    .img_left_side_box span.two {
        font-size: 19px;
    }
    .market_cup h3 span.one {
        font-size: 25px;
    }
}
@media screen and (max-width:779px){
    .img_left_side_box .stronge_head {
        font-size: 20px;
    }
    .market_cup h3 span.one {
        font-size: 20px;
    }
    .img_left_side_box span.two {
        font-size: 15px;
    }
    .market_cup h3 span.two {
        font-size: 19px;
    }
    .img_left_side_box span.three {
        font-size: 15px;
    }
}
@media screen and (max-width:650px){
    .img_left_side_box > img {
        height: 68px;
        left: 0%;
    }
    .img_left_side_box .stronge_head {
        font-size: 17px;
    }
    .market_cup h3 span.one {
        font-size: 17px;
    }
    .market_cup h3 span.two {
        font-size: 16px;
    }
    .buy_now {
        font-size: 15px;
    }
}
@media screen and (max-width:551px){
    .img_left_side_box .stronge_head {
        font-size: 25px;
    }
    .img_left_side_box span.two {
        font-size: 20px;
    }
    .market_cup h3 span.one {
        font-size: 25px;
    }
    .margin-top-two {
        margin-top: 15px;
    }
    .market_cup h3 span.two {
        font-size: 19px;
    }
}
@media screen and (max-width: 400px) {
    div.col-lg-6 > canvas{
        height: 33vh !important;
    }
}

@media only screen and (max-width: 376px){
    .img_left_side_box .stronge_head {
        font-size: 25px;
        line-height: 20px;
    }
    .img_left_side_box span.two {
        font-size: 17px;
        letter-spacing: 2px;
    }
    .img_left_side_box span.three {
        font-size: 18px;
        color: green;
        letter-spacing: 1px;
        line-height: 31px;
    }
    .market_cup h3 span.one {
        font-size: 20px;
    }
    .market_cup h3 span.two {
        font-size: 17px;
    }
    .buy_now {
        padding: 12px 34px;
        font-size: 20px;
    }
}