
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.margin-top{
    margin-top: 50px;
}
.chart_animation h2{
    color: white;
    letter-spacing: 1px;
    text-align: center;
    margin: 50px auto;
    font-size: 20px;
    font-family: 'Lato', sans-serif;
}
.own_container{
    max-width: unset;
    float: left;
    width: 85%;
    display: block;
    margin: auto;
    position: relative;
    margin-left: 176px;
}
ul.nav-tabs li a img{
    height: 30px;
    border-radius: 30px;
    margin-right: 10px;
}
.tab{ font-family: 'Lato', sans-serif; }

.tab .nav-tabs{ 
    border: none;
    padding: 10px 0;
    background: #0D203C;
 }

.tab .nav-tabs li a{
    color: white;
    /* background: #fff; */
    font-size: 19px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    padding: 8px 25px 10px;
    margin: 0 10px 0 0;
    border: none;
    /* border-top: 3px solid #0670bc; */
    border-radius: 0;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) inset;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease 0s;
}

.tab .nav-tabs li.active a,
.tab .nav-tabs li a:hover,
.tab .nav-tabs li.active a:hover{
    color: #fff;
    background: transparent;
    border: none;
    border-bottom: 3px solid #0670bc70;
}

.tab .nav-tabs li a:before,
.tab .nav-tabs li a:after{
    content: "";
    background-color: #0670bc70;
    width: 50%;
    height: 100%;
    opacity: 0;
    transform: perspective(300px) rotateX(-100deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s ease 0s;
}

.tab .nav-tabs li a:after{
    transform: perspective(300px) rotateX(100deg);
    left: auto;
    right: 0;
    transition: all 0.4s ease 0.1s;
}
.MuiFormControl-root {
    margin-top: 35px !important;
}
.tab .nav-tabs li.active a:before,
.tab .nav-tabs li a:hover:before,
.tab .nav-tabs li.active a:after,
.tab .nav-tabs li a:hover:after{
    opacity: 1;
    transform: perspective(300px) rotateX(0);
}
.tab-content>.active {
    display: flex;
}
.tab .tab-content{
    position: relative;
    margin-top: 20px;
    padding-top: 30px;
}
.container-fluid .fade:not(.show) {
    opacity: 1;
}
.vollet_btn {
    display: flex;
    margin: 10px auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
.dash_search_box {
    padding: 0;
    margin: 0;
    width: 100%;
    position: relative;
}
.dash_search_box input{
    font-family: 'Lato', sans-serif;
    height: 50px;
    width: 50%;
    border-radius: 11px;
    border: 1px solid grey;
    outline: none;
    background: #80808038;
    color: white;
    padding-left: 20px;
}
.dash_search_box input::placeholder{
    color: white;
    letter-spacing: 2px;
    font-family: 'Lato', sans-serif;
}
.dash_search_box i{
    position: relative;
    top: 0px;
    right: 35px;
    font-size: 20px;
    color: white;
}
.vollet_btn button{
    font-family: 'Lato', sans-serif;
    background: linear-gradient(90deg,#c09434,#caa344,#d3b154,#ddc064,#e7cf75,#e7cf75,#e7cf75,#e7cf75,#ddc064,#d3b154,#caa344,#c09434);
    color: #443107;
    border: none;
    padding: 0px 34px;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 18px;
    transition: all 0.5s;
    overflow: hidden;
    position: relative;
    height: 50px;
    line-height: 50px;
    margin: auto;
    display: block;
}
.connected_btn{
    border: 1px solid palegoldenrod;
    padding: 10px 31px;
    border-radius: 17px;
}
.connected_btn h1{
    color: #F4E286;
    font-size: 25px;
    letter-spacing: 2px;
    padding-left: 10px;
    padding-top: 10px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    position: relative;
    left: -16px;
}
.connected_btn p{
    padding: 0;
    margin: 0;
    color: white;
    font-size: 13px;
}
.vollet_msg p{
    color: white;
    letter-spacing: 1px;
    margin-top: 12px;
    text-align: center;
}
.sign_up a:hover{
  color: #443107;
}
.sign_up > a{
  color: #443107 !important;
  font-weight: 600 !important;
}

.vollet_btn button:before,
.vollet_btn button:after{
    content: "";
    background-color: #8f939396;
    height: 100%;
    width: 50%;
    opacity: 1;
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 0;
    transition: all 0.4s ease-out 0.1s;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.vollet_btn button:after{
    opacity: 0;
    transform: scale(0.9,0.7);
    left: 0;
    transition: all 0.3s ease-out 0s;
}

.vollet_btn button:hover:before{ left: 100%; }

.vollet_btn button:hover:after{
    opacity: 0.3;
    transform: scale(1);
}
.vollet_btn button img{
    margin-right: 5px;
}
.scd_width{
    width: 40% !important;
}
.col_iframe{
    width: 100% !important;
    background: hsla(0,0%,100%,.05);
    /* margin: 10px 0; */
}
.tv-embed-widget-wrapper__body {
    height: 555px !important;
}
.col_iframe > div > iframe{
    width: 100%;
    height: 550px;
}
#widget-container {
    border: 1px solid #F4E286 !important;
}
.own_flex_class{
    display: flex;
    flex-wrap: wrap;
}
.trandingview{
    color: #ecde84;
    font-weight: 700;
    letter-spacing: 3px;
    padding-top: 12px;
    padding-left: 20px;
}
.data_btns {
    margin: 10px;
    display: flex;
    justify-content: center;
    background: #80808052;
    border-radius: 10px;
}
.data_btns > span {
    display: inline-block !important;
    padding: 5px 20px !important;
    margin: 7px 10px;
    width: auto;
    color: white;
    background: unset;
    border: 1px solid #F4E286 !important;
    transition: all .6s linear;
    font-family: 'Lato', sans-serif !important;
    font-weight: 600 !important;
}
.data_btns span:hover{
    background: linear-gradient(90deg,#c09434,#caa344,#d3b154,#ddc064,#e7cf75,#e7cf75,#e7cf75,#e7cf75,#ddc064,#d3b154,#caa344,#c09434);
    transition: all .5s linear;
    color: #443107;
}
.display_two{
    display: none;
}

.chart_animation h2{
    color: white;
    letter-spacing: 1px;
    text-align: center;
    margin: 50px auto;
    font-size: 20px;
    font-family: 'Lato', sans-serif;
}

#reactgooglegraph-2 > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(2) > g:nth-child(1) > rect:nth-child(1),
#reactgooglegraph-2 > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(2) > g:nth-child(2) > rect:nth-child(2),
#reactgooglegraph-2 > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(2) > g:nth-child(3) > rect:nth-child(3),
#reactgooglegraph-2 > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(2) > g:nth-child(4) > rect:nth-child(4),
#reactgooglegraph-2 > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(2) > g:nth-child(5) > rect:nth-child(5),
#reactgooglegraph-2 > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(2) > g:nth-child(6) > rect:nth-child(6),
#reactgooglegraph-2 > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(2) > g:nth-child(7) > rect:nth-child(7),
#reactgooglegraph-2 > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(2) > g:nth-child(8) > rect:nth-child(8){
    fill: transparent;
}
@media only screen and (max-width: 1500px){
    .own_container {
        width: 100%;
        margin: auto;
        margin-left: unset !important;
    }
    .tabs_flex{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .tab .tab-content {
        position: relative;
        /* width: 80%; */
    }

    /* .header_banner {
        width: 95%;
        background-size: contain;
    } */
    marquee {
        width: 100%;
    }
    ul.nav-tabs li{
        margin-top: 20px;
    }
    .tab .nav-tabs {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media only screen and (max-width: 1200px){
    .col_iframe {
        width: 100%;
    }
    .responsive_col{
        flex: unset !important;
        max-width: unset !important;
        width: 100% !important;
    }
    .display_one{
        display: none;
    }
    .display_two{
        display: block;
        flex: unset !important;
        max-width: unset !important;
        width: 50% !important;
    }
    .own_res{
        flex: unset !important;
        max-width: unset !important;
        width: 50% !important;
    }
    .trades_col{
        flex: unset !important;
        max-width: unset !important;
        width: 50% !important;
    }
    .table_trades table {
        overflow: scroll !important;
    }
}
@media screen and (max-width:992px){
    .connected_btn {
        padding: 2px 18px;
    }
    .connected_btn h1 {
        font-size: 16px;
        left: 0;
    }
    .dash_search_box input{
        width: 85%;
    }
}
@media screen and (max-width:885px){
    .trades_col{
        flex: unset !important;
        max-width: unset !important;
        width: 100% !important;
    }
    .display_two {
        width: 100% !important;
    }
}
@media screen and (max-width:778px){
    .dash_search_box input{
        width: 100%;
    }
    .vollet_btn button {
        margin-top: 20px;
    }
    
.connected_btn {
    padding: 2px 18px;
    margin-top: 20px;
}
    
    .dash_search_box input{
        display: block;
        margin: auto;
    }
    .dash_search_box i {
        position: relative;
        top: 15px;
        right: 32px;
        font-size: 20px;
        color: white;
        position: absolute;
    }
    .vollet_btn {
        flex-wrap: wrap;
    }
    ul.nav-tabs li{
        margin-top: 20px;
    }
    ul.nav-tabs li a img {
        height: 20px;
        margin-right: 5px;
    }
    .tab .nav-tabs li a {
        font-size: 14px;
    }
    .side_menu .wrapper .menu-btn {
        top: 8px;
    }
}
@media screen and (max-width:650px){
    .display_two{
        width: 100% !important;
    }
    .own_res{
        width: 100% !important;
        margin-bottom: 20px !important;
    }
}
@media screen and (max-width:568px){
    .header_banner {
        height: 66px;
    }
    .MuiTableCell-body > img {
        object-fit: contain;
    }
}
@media screen and (max-width:500px){
    .img_left_side_box {
        justify-content: center;
    }
    .img_left_side_box > img {
        padding: 10px;
    }
    .own_flex_classes {
        display: flex;
        flex-direction: column;
    }
    .market_cup h3{
        display: unset !important;
    }
    .market_cup{
        justify-content: center;
    }
    .market_cup h3 span.one {
        margin: 0 10px;
    }
    .buy_now {
        padding: 7px 14px;
        margin: 0 10px;
        display: block;
        margin: auto;
    }
    .MuiTable-root {
        width: 100% !important;
        display: block !important;
        overflow-x: scroll !important;
    }
    .MuiTableCell-body > img {
        object-fit: contain;
        height: 22px;
        position: relative;
        top: 7px;
    }
    th.MuiTableCell-body div span{
        font-size: 14px !important;
    }
    td.MuiTableCell-alignRight {
        font-size: 12px;
    }
    .tab .tab-content {
        padding: 0;
    }
    .img_left_side_box h3 {
        line-height: 0.5;
    }
}
@media only screen and (max-width: 479px){
    .tab .nav-tabs li a{
         margin: 0 0 10px;
     }
     .header_banner {
        height: 50px;
        margin-top: 16px;
    }
    .data_btns {
        flex-direction: row;
        align-items: center;
    }
    .data_btns > span {
        padding: 6px 13px !important;
        margin: 0px 5px;
        font-weight: 500 !important;
        font-size: 10px;
    }
    .left-right-head {
        font-size: 19px;
    }
    .comming_text {
        font-size: 17px;
    }
   button.MuiPaginationItem-root {
        color: gold;
        font-size: 10px;
        padding: 0;
        margin: 0;
        height: 25px;
    }
    .MuiPagination-root{
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 24px 0 !important;
        padding: unset !important;
    }
}
@media only screen and (max-width: 376px){
    .dash_footer span {
        font-size: 13px;
    }
}