@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.Term {
	margin-top: 180px;
	background: white;
 
}
.term-1 {
	padding: 21px 31px;
}
.term-heading {
	font-family: "Lato", sans-serif;
	text-align: center;
	font-weight: 700;
	font-size: 52px;
	padding: 20px 20px;
}
.Term p {
	font-family: "Lato", sans-serif;
	text-align: center;
	font-size: 20px;
}
.term-pic {
	background-image: url(/src/images/bitcoin-bg.jpg);
	background-position: center;
	background-size: cover;
	width: 100%;
	height: 300px;
}
.term-btn {
    background-color: #ffd475;
    width: 31%;
    border-radius: 6px;
    color: #443107;
    box-shadow: 2px 2px;
    height: 54px;
    position: relative;
    left: 250px;
    font-size: 22px;
    font-weight: 500;
}
.term-btn:hover {
	transform: rotate(0deg) scale(0.925) skew(0deg) translate(-9px);
	-webkit-transform: rotate(0deg) scale(0.925) skew(0deg) translate(-9px);
	-moz-transform: rotate(0deg) scale(0.925) skew(0deg) translate(-9px);
	-o-transform: rotate(0deg) scale(0.925) skew(0deg) translate(-9px);
	-ms-transform: rotate(0deg) scale(0.925) skew(0deg) translate(-9px);
}
.term-banner {
	background-image: url(../images/Coming.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 400px;
}
.term-list {
	display: flex;
	flex-direction: column;
	line-height: 11px;
}
.term-list ol {
	font-family: "Lato", sans-serif;
	font-size: 18px;
	padding: 6px 43px;
	position: relative;
	bottom: 25px;
}
.term-list i {
	color: rgb(19, 19, 68);
}

@media screen and (max-width: 1220px) {
	.term-btn {
		position: relative;
		left: 140px;
		width: 30%;
	}
    .term-list ol{
        font-size: 16px;
    }
}
@media screen and (max-width: 1010px){
    .term-list ol{
        font-size: 14px;
        line-height: 2px;
    }
}
@media screen and (max-width: 920px){
    .term-list ol{
        font-size: 12px;
line-height: 13px;

    }
}

@media screen and (max-width: 480px) {
	.term-btn {
		font-size: 18px;
		position: relative;
        left: 148px;
		margin-bottom: 5px;
		height: 39px;
	}
    .term-pic{
        height: 225px;
    }
    .term-banner {
        height: 200px;
        background-size: cover;
        background-position: center;
    }
    .term-1 h1{
        font-size: 22px;
        text-align: center;
        
    }
    .term-1 p{
        font-size: 12px;
        font-family: 200;
    }
    .term-list ol {
        font-family: "Lato", sans-serif;
        font-size: 13px;
        padding: 0px 38px;
        position: relative;
        bottom: 20px;
    }
}
@media screen and (max-width: 380px){
    .term-btn{
        
    width: 30%;
    position: relative;
    left: 127px;
    height: 34px;


    }
    .term-list ol {
            font-family: "Lato", sans-serif;
            font-size: 11px;
            padding: 0px 36px;
            position: relative;
            bottom: 20px;
        }
    
}
@media screen and (max-width: 768px){
    .term-heading{
        font-size: 39px;
    }
    .term-btn{
        position: relative;
        left: 285px;
        width: 24%;
        height: 42px;
        margin-bottom: 4px;

    }
    .term-banner{
        width: 100%;
        height: 200px;
    }
    .term-list ol{
        font-size: 12px;
    }
    .term-list i{
        font-size: 24px;
    }
}

@media screen and (max-width: 766px){
    .term-banner {
        height: 330px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        }
    .sec-row{
        display: flex;
        flex-direction: column-reverse;
    }
}