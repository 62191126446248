@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
.banner-clss{
    background-image: url('../images/banner-bg.png');
    margin-top: 131px;
    width: 100%;
    height: 699px;
    background-size: cover;
    align-items: center;
    display: flex;
}
.validate small{
    color: white;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 2px;
}
.validate h3 {
    color: #FFD475;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    letter-spacing: 2px;
    font-weight: 700;
}
.validate .main_title_banner .banner_h4{
    color: white;
    letter-spacing: 4px;
    font-family: 'Exo 2', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 87px;
    font-size: 65px;
}


.validate .main_title_banner .banner_phara{
    font-family: 'Lato', sans-serif;
    color: white;
    letter-spacing: 2px;
    font-size: 20px;
    font-weight: 500;
}
 .btn_banner button{
    font-family: 'Lato', sans-serif;
    background: linear-gradient(90deg,#c09434,#caa344,#d3b154,#ddc064,#e7cf75,#e7cf75,#e7cf75,#e7cf75,#ddc064,#d3b154,#caa344,#c09434);
    color: #443107;
    border: none;
    padding: 10px 50px;
    margin: 10px 30px;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 20px;
    transition: all 0.5s;
    overflow: hidden;
    position: relative;
}
 .btn_banner button:hover{
    color: #fff;
    animation: swoosh 0.4s ease-out 0.2s;
}

 .btn_banner > button:before,
.btn_banner > button:after{
    content: "";
    background-color: #8f939396;
    height: 100%;
    width: 50%;
    opacity: 1;
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 0;
    transition: all 0.4s ease-out 0.1s;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

 .btn_banner button:after{
    opacity: 0;
    transform: scale(0.9,0.7);
    left: 0;
    transition: all 0.3s ease-out 0s;
}

.btn_banner button:hover:before{ left: 100%; }

.btn_banner button:hover:after{
    opacity: 0.3;
    transform: scale(1);
}
.col-lg-6.video > div{
    width: unset !important;
    height: unset !important;
}

.section{
    background: white;
    margin-top: 80px;
    padding: 40px 0;
}
.token_sec_img > div > img{
    width: 90%;
    position: relative;
    float: right;
}
.token_sec_text .token_head .head{
    font-family: 'Exo 2', sans-serif;
    color: #c19636;
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 2px;
    display: inline-block;
    padding-left: 15px;
    text-transform: uppercase;
}
.token_sec_text .token_head{
    display: flex;
    align-items: flex-start;
}
.token_sec_text .token_head img{
    object-fit: contain;
    position: relative;
    top: -5px;
}
.token_sec_text .token_head .head .border_bnottom{
    display: block;
    height: 4px;
    width: 58%;
    background: #c19636;
    float: right;
    position: relative;
    top: 10px;
    right: -71px;
}
.token_sec_text .heading {
    font-family: 'Exo 2', sans-serif;
    margin-top: 8px;
    font-size: 42px;
    letter-spacing: 1px;
    text-transform: capitalize;
    font-weight: 700;
    color: #404040;
    line-height: 49px;
    padding-right: 125px;
    text-align: left;
}
.token_sec_text .para_text{
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    letter-spacing: 1px;
    padding-right: 108px;
    color: #919191;
    line-height: 27px;
}



/* // SERVICES BOXES // */
.services_btn{
    background: #0b2251 !important;
    color: #ffd475 !important;
    font-weight: 400 !important;
}
.custom_conatiner{
    width: 90% !important;
    padding: auto;
}
.services_heading{
    color: #c19636;
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    font-size: 45px;
    margin-top: 70px;
    display: flex;
    justify-content: center;
}
.services_heading .border_head{
    position: relative;
    top: 25px;
}
.services_heading .left{
    display: block;
    height: 5px;
    width: 8%;
    background: #c19636;
    margin-right: 20px;
}
.services_heading .right{
    display: block;
    height: 5px;
    width: 8%;
    background: #c19636;
    margin-left: 20px;
}
.serviceBox{
    text-align: center;
    background: white;
    padding: 125px 42px 42px 42px;
    margin: 0 0px;
    border-radius: 20px;
}

.service-icon{
    color: #fff;
    font-size: 78px;
    background-color: #c19636;
    width: 140px;
    height: 140px;
    margin: 0 auto 10px;
    border-radius: 68px;
    text-align: center;
    /* vertical-align: middle; */
    display: block;
    position: relative;
    top: 75px;
}  
.service-icon span > img{
    height: 60px;
}
.serviceBox .title{
    color: #0b2251;
    font-family: 'Exo 2', sans-serif;
    font-size: 22px;
    font-weight: 800;
    text-transform: uppercase;
    margin: 0 0 5px;
    letter-spacing: 2px;
    line-height: 32px;
}

.serviceBox .description{
    font-family: 'Lato', sans-serif;
    color: #919191;
    line-height: 30px;
    letter-spacing: 1px;
    font-size: 19px;
    padding-top: 20px;
}

/* // SUBSCRIPE SECTION // */
.subscripe h4{
    font-family: 'Lato', sans-serif;
    color: #0b2251;
    letter-spacing: 1px;
    font-weight: 800;
    font-size: 30px;
}
.subscripe p{
    font-family: 'Lato', sans-serif;
    color: #919191;
    font-size: 17px;
    letter-spacing: 1px;
}
.subcripe_field > div{
    display: inline-flex;
    width: 100%;
}
.subcripe_field > div > input{
    width: 70%;
    height: 65px;
    padding: 20px;
}
.subcripe_field .btn_banner .btn_one{
    border: none;
    padding: 17px 42px;
    letter-spacing: 1px;
    margin: 0;
    border-radius: 0;
    color: white;
    background: #0b2251;
    margin: 0 16px;
    letter-spacing: 4px;
}



/* // RESPONSIVE AREA // */
@media only screen and (max-width: 1400px){
    .validate .main_title_banner .banner_h4 {
        font-size: 50px;
    }
}
@media only screen and (max-width: 1400px){
.validate .main_title_banner .banner_h4 {
    line-height: 45px;
    font-size: 35px;
}
.banner-clss {
    margin-top: 50px;
}
}
@media screen and (max-width: 1200px){
    .token_sec_text .token_head .head {
        font-size: 30px;
    }
    .token_sec_text .heading {
        margin-top: 28px;
        font-size: 25px;
        line-height: 29px;
        padding-right: unset !important;
    }
    .token_sec_text .para_text {
        font-size: 16px;
        padding-right: 0px;
        line-height: 27px;
    }
    .serviceBox {
        padding: 103px 5px 42px 5px;
    }
    .serviceBox .title {
        font-size: 20px;
        margin: 0 0 5px;
        letter-spacing: 1px;
        line-height: 26px;
    }
    .serviceBox .description {
        line-height: 23px;
        letter-spacing: 1px;
        font-size: 15px;
    }
}
@media only screen and (max-width: 992px){
    .banner-clss {
        margin-top: 242px;
    }
    .token_sec_text .heading {
        font-size: 20px;
        line-height: 23px;
        padding-right: unset !important;
    }
    .token_sec_img > div > img {
        display: none;
    }
    .col_one{
        width: 50%;
    }
}
@media only screen and (max-width: 768px){
    .banner-clss {
        margin-top: 165px;
    }
    .banner_h4 {
        line-height: 40px !important;
        font-size: 30px !important;
    }   
    .serviceBox .title {
        font-size: 17px;
        line-height: 25px;
    } 
    .btn_banner button {
        padding: 10px 50px;
        margin: 10px auto;
        font-size: 17px;
    }
    .col_one{
        width: 80%;
        margin: auto;
    }
    .col_large{
        width: 80%;
        margin: auto;
    }
}
@media only screen and (max-width: 600px){
    .banner_h4 {
        line-height: 32px !important;
        font-size: 22px !important;
    } 
    .validate .main_title_banner .banner_phara {
        font-size: 16px;
    }
    .btn_banner button {
        padding: 10px 14px;
        margin: 11px 6px;
        font-size: 13px;
    }
    *, ::after, ::before {
        box-sizing: border-box;
        overflow-x: clip;
    }
    .banner-clss {
        margin-top: 111px;
    }
    .services_heading {
        font-size: 30px !important;
        margin-top: 30px !important;
    }
    .services_heading .right,
    .services_heading .left {
        width: 25%;
    }
    .subscripe h4 {
        font-size: 25px;
    }
    .subcripe_field > div {
        display: block;
        width: 100%;
    }
    .subcripe_field > div > input {
        width: 100%;
    }
    .subcripe_field .btn_banner .btn_one {
        margin: 9px auto;
        display: block;
    }
    .serviceBox {
        margin: 0 0 0px !important;
    }
    .serviceBox {
        padding: 89px 5px 8px 0px !important;
    }
    .service-icon {
        color: #fff;
        font-size: 65px;
        background-color: #c19636;
        width: 120px;
        height: 120px;
        margin: 0 auto 2px;
        border-radius: 68px;
        display: block;
        position: relative;
        top: 65px;
    }
    .service-icon span > img {
        height: 40px;
    }    
    .serviceBox .title {
        font-size: 15px;
        line-height: 21px;
    }
    .serviceBox .description {
        line-height: 18px;
        letter-spacing: 1px;
        font-size: 12px;
    }
}
@media only screen and (max-width: 480px){
    .banner_h4 {
        line-height: 25px !important;
        font-size: 19px !important;
    }
    .banner_phara {
        font-size: 14px;
    }
    .validate small {
        color: white;
        font-size: 25px;
    }    
    .banner-clss {
        margin-top: 90px;
    }
    .token_sec_text .heading {
        font-size: 18px;
        line-height: 22px;
    }
    .token_sec_text .para_text {
        line-height: 23px;
    }
    .col_one{
        width: 100%;
    }
    .col_large{
        width: 100%;
        margin: auto;
    }
    .service-icon {
        font-size: 52px;
        width: 100px;
        height: 100px;
        top: 55px;
    }
    .serviceBox {
        padding: 66px 5px 8px 0px !important;
    }
    .subscripe h4 {
        font-size: 15px;
    }
    .subscripe p {
        font-size: 13px;
        line-height: 17px;
    }
    .subcripe_field > div > input {
        height: 50px;
    }
    .btn_one {
        margin: 9px 0 !important;
    }
    .subcripe_field .btn_banner .btn_one {
        padding: 12px 30px;
    }
}
@media only screen and (max-width: 990px){
    .serviceBox{ margin: 0 0 40px; }
}