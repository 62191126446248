
.contactForm {
	background-image: url(../images//banner-bg.png);
	margin-top: 120px;
	height: 100vh;
}

.a-one,
.a-two,
.a-three,
.a-four,
.a-five,
.a-six,
.a-seven,
.a-eight .a-nine,
.a-ten {
	height: 15px;
	width: 15px;
}
.a-one,
.a-two,
.a-three,
.a-four,
.a-five,
.a-six,
.a-seven,
.a-eight .a-nine,
.a-ten {
	opacity: 0.6;
}

.a-one {
	background: -webkit-linear-gradient(0deg, #ff5d06, #ffc806 50%);
	border-radius: 50%;
	position: absolute;
	bottom: 0;
	left: 150px;
	animation: MoveUpDown 5s linear infinite;
}

.a-two {
	background: -webkit-linear-gradient(0deg, #58f106, #23c10e 50%);
	border-radius: 50%;
	position: absolute;
	bottom: 10px;
	left: 200px;
	animation: MoveUpDownTwo 9s linear infinite;
}

.a-three {
	background: -webkit-linear-gradient(0deg, #862ff1, #163fae 50%);
	border-radius: 50%;
	position: absolute;
	bottom: 120px;
	left: 300px;
	animation: MoveUpDownThree 5s linear infinite;
}

.a-four {
	background: -webkit-linear-gradient(0deg, #d406b7, #fc1a66 50%);
	border-radius: 50%;
	position: absolute;
	bottom: 0;
	right: 0;
	animation: MoveUpDownFour 15s linear infinite;
}

.a-five {
	background: -webkit-linear-gradient(0deg, #58f106, #23c10f 50%);
	border-radius: 50%;
	position: absolute;
	bottom: 200px;
	right: 175px;
	animation: MoveUpDownFive 8s linear infinite;
}

.a-six {
	background: -webkit-linear-gradient(0deg, #fe5f12, #fec412 50%);
	border-radius: 50%;
	position: absolute;
	bottom: 250px;
	right: 200px;
	animation: MoveUpDownSix 7s linear infinite;
}

.a-seven {
	background: -webkit-linear-gradient(0deg, #d622bf, #fb196a 50%);
	border-radius: 50%;
	position: absolute;
	bottom: 0;
	left: 450px;
	right: 0;
	animation: MoveUpDownSeven 6s linear infinite;
}

.a-eight {
	background: -webkit-linear-gradient(0deg, #60f112, #30c719 50%);
	opacity: 0.4;
	border-radius: 50%;
	position: absolute;
	bottom: 0;
	left: 350px;
	animation: MoveUpDownEight 11s linear infinite;
}

.a-nine {
	background: -webkit-linear-gradient(0deg, #fe5f12, #fec412 50%);
	border-radius: 50%;
	position: absolute;
	bottom: 0;
	left: 450px;
	animation: MoveUpDownNine 12s linear infinite;
}

.a-ten {
	background: -webkit-linear-gradient(0deg, #842af1, #0f40aa 50%);
	border-radius: 50%;
	position: absolute;
	bottom: 0;
	right: 0;
	animation: MoveUpDownTen 14s linear infinite;
}

@keyframes a-one {
	from {
		top: 0px;
		left: 200px;
	}
	to {
		top: 100px;
		left: 100px;
	}
}

@keyframes MoveUpDown {
	0%,
	100% {
		bottom: 0;
	}
	50% {
		top: -100px;
		z-index: 9;
	}
	50% {
		bottom: 300px;
	}
}

@keyframes MoveUpDownTwo {
	8% {
		left: 30px;
	}
	30% {
		left: 150px;
	}
	60% {
		left: 250px;
	}
	100% {
		left: 100px;
	}
	50% {
		bottom: 500px;
	}
}

@keyframes MoveUpDownThree {
	0%,
	100% {
		bottom: 0;
	}
	50% {
		bottom: 400px;
	}
}

@keyframes MoveUpDownFour {
	15% {
		right: 30px;
	}
	35% {
		right: 150px;
	}
	35% {
		right: 250px;
	}
	50% {
		right: 150px;
	}
	80% {
		right: 450px;
	}
	100% {
		bottom: 0;
	}
	50% {
		bottom: 350px;
	}
}

@keyframes MoveUpDownFive {
	20% {
		right: 100px;
	}
	100% {
		bottom: 0;
	}
	50% {
		bottom: 250px;
	}
}

@keyframes MoveUpDownSix {
	25% {
		right: 150px;
	}
	35% {
		right: 50px;
	}
	100% {
		bottom: 0;
	}
	50% {
		bottom: 500px;
	}
}

@keyframes MoveUpDownSeven {
	0% {
		left: 50px;
	}
	25% {
		left: 300px;
	}
	100% {
		bottom: 0;
	}
	50% {
		bottom: 375px;
	}
}

@keyframes MoveUpDownEight {
	0%,
	100% {
		bottom: 0;
	}
	50% {
		bottom: 370px;
	}
}

@keyframes MoveUpDownNine {
	0%,
	100% {
		bottom: 0;
	}
	50% {
		bottom: 475px;
	}
}

@keyframes MoveUpDownTen {
	5% {
		right: 50px;
	}
	30% {
		right: 500px;
	}
	100% {
		bottom: 0;
	}
	50% {
		bottom: 500px;
	}
}

.custom-flex {
	justify-content: center;
	display: inline-flex;
	width: 86%;
}

.custom-flex .form-group > label > i {
	font-size: 18px;
	position: relative;
	top: 4px;
}
.custom-flex .control-label {
	position: relative;
	bottom: 0px;
	display: flex;
	justify-content: center;
	left: 33px;
}
.cont-heading {
	display: flex;
	justify-content: center;
	font-size: 25px;
	text-transform: uppercase;
	color: white;
	font-weight: 600;
	font-family: "Lato", sans-serif;
	display: block;
	text-align: center;
}

.custom-flex input.form-control::placeholder {
	padding-left: 8px;
	text-transform: capitalize;
	letter-spacing: 1px;
	font-weight: 500;
}
.contact-form-horizontal {
	width: 50%;
	position: relative;
	height: 600px;
	letter-spacing: 1px;
	background: #092a4d;
	border-radius: 5px;
	border: 2px solid #fff7cc;
	display: block;
	margin: 100px auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 120px;
}

.contact-form-horizontal:before {
	background-image: url(../images/formlogo.svg) !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
	content: "";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	width: 139px;
	height: 137px;
	line-height: 118px;
	border-radius: 50%;
	border: 3px solid #fff7cc;
	background: #000000;
	font-size: 40px;
	color: #fff;
	text-align: center;
	margin: 0 auto;
	position: absolute;
	top: -62px;
	left: -1px;
	right: 0;
}
.contact-row {
	display: flex !important;
	justify-content: center !important;
}
.custom-flex .form-group {
	background: transparent !important;
	font-family: "Lato", sans-serif;
	display: flex;
	justify-content: center;
	margin-top: 30px;
}
.fa-envelope:before {
	content: "\f0e0";
	position: relative;
	top: 6px;
	color: white;
}
.fa-user:before {
	color: white;
	content: "\f007";
}
.fa-phone:before {
	content: "\f095";
	color: white;
}
.form-control {
	background: transparent !important;
	display: block;
	width: 88%;
	padding: 0.375rem 2.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	-webkit-appearance: none;
	appearance: none;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.msg_box {
	width: 93%;
	margin-left: 14px;
}
.text-area-form {
	border: 2px solid white;
	background: transparent;
	font-family: "Lato", sans-serif;
	margin-top: 30px !important;
	width: 88%;
	margin: auto;
	border-radius: 10px;
	display: block;
	padding-left: 30px;
	padding-top: 10px;
}
.text-area-form:focus {
	outline: none;
}
.text-area-form::placeholder {
	text-transform: capitalize;
	letter-spacing: 2px;
	font-weight: 500;
}
.btn-contact {
	overflow: hidden;
	position: relative;
	background: linear-gradient(
		90deg,
		#c09434,
		#caa344,
		#d3b154,
		#ddc064,
		#e7cf75,
		#e7cf75,
		#e7cf75,
		#e7cf75,
		#ddc064,
		#d3b154,
		#caa344,
		#c09434
	);
	color: #443107;
	outline: none;
	font-size: 17px;
	font-weight: 700;
	display: flex;
	flex-wrap: wrap;
	margin: 75px auto;
	padding: 0;
	border-radius: 6px;
	width: 120px;
	height: 39px;
	align-items: center;
	justify-content: center;
	font-family: "Lato", sans-serif;
}
.btn-contact:before,
.btn-contact:after {
	content: "";
	background-color: #8f939396;
	height: 100%;
	width: 50%;
	opacity: 1;
	position: absolute;
	top: 0;
	left: -100%;
	z-index: 0;
	transition: all 0.4s ease-out 0.1s;
	clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.btn-contact:after {
	opacity: 0;
	transform: scale(0.9, 0.7);
	left: 0;
	transition: all 0.3s ease-out 0s;
}

.btn-contact:hover:before {
	left: 100%;
}

.btn-contact:hover:after {
	opacity: 0.3;
	transform: scale(1);
}

@media screen and (max-width: 1400px) {
	.contactForm {
		height: 110vh;
	}
}

@media screen and (max-width: 1399px) {
	.custom-flex {
		margin-inline-end: 11px;
	}
	.custom-flex .form-group {
		width: 50%;
		margin-left: 4px;
	}
}

@media screen and (max-width: 1199px) {
	.contact-form-horizontal:before {
		width: 120px;
		height: 120px;
	}
  .custom-flex {
    width: 90%;
}
.form-control {
  font-size: 14px;
  padding-left: 24px;
  margin-left: 5px;
  padding-bottom: 7px;
}

}

@media screen and (max-width:991px) {
        
  .custom-flex {
    justify-content: center;
    display: inline-flex;
    width: 99%;
    margin-right: 19px;
    height: 70px;
}
.form-control {
  font-size: 13px;
  padding-left: 24px;
  margin-left: 5px;
  padding-bottom: 7px;
}
.text-area-form {
  width: 100%;
  padding-left: 17px;  
  }
  .contact-form-horizontal {
    width: 64%;
    height: 569px;
  }
  .msg_box {
    width: 92%;
    margin-left: 2px;
}
}

@media screen and (max-width:768px) {
	.custom-flex {
		width: 100%;
		margin-right: 24px;
		height: 70px;
	}
	.cont-heading {
		font-size: 21px;
	}
	.contact-form-horizontal {
		width: 91%;
		overflow: visible;
	}
}
@media screen and (max-width:601px){
	.contact-sec{
		padding-left: unset !important;
		width: auto !important;
	}
}
@media screen and (max-width:400px){
	.custom-flex {
		justify-content: center;
		display: inline-flex;
		width: 96%;
		margin-right: 27px;
		height: 63px;
	}
}
@media screen and (max-width:300px){
	.contact-form-horizontal:before {
		width: 120px;
		height: 120px;
	
	}
	.cont-heading {
		font-size: 17px;
	}
	 
	 .form-control i{
		 content: none;
	 }
}
